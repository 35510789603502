import React, { useState, useEffect } from "react";

const FORM_ENDPOINT =
  "https://getform.io/f/31b0396f-807c-4f67-8646-3af27aed1c5b";
const HONEYPOT_ENDPOINT =
  "https://getform.io/f/2f654a4f-8ebf-47ed-a76b-7551c70f0172";

export const ContactForm = () => {
  const [endpoint, setEndpoint] = useState(HONEYPOT_ENDPOINT);
  const [honeypot, setHoneypot] = useState("");

  /* Use a little bit of client-side JS to prevent spam bots:
   * - if JS is deactivated => form has honeypot action
   * - if the hidden textarea is filled => form has honeypot action
   */
  useEffect(() => {
    if (honeypot) {
      setEndpoint(HONEYPOT_ENDPOINT);
    } else {
      setEndpoint(FORM_ENDPOINT);
    }
  }, [honeypot]);

  return (
    <div className="relative bg-white pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="relative max-w-3xl mx-auto">
        <div className="text-center mb-8">
          <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
            Demander une démonstration personnalisée
          </h2>
        </div>

        <form
          className="space-y-8 divide-y divide-gray-200"
          method="post"
          action={endpoint}
        >
          <div className="space-y-8 divide-y divide-gray-200">
            <div className="pt-8">
              <div className="mb-12 text-base text-gray-500">
                <p>
                  Notre équipe est à votre disposition pour vous présenter la
                  solution et discuter de sa mise en place dans votre contexte.
                </p>
              </div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label
                    htmlFor="first-name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Prénom&nbsp;*
                  </label>
                  <div className="mt-1">
                    <input
                      required
                      type="text"
                      name="first-name"
                      id="first-name"
                      autoComplete="given-name"
                      className="shadow-sm focus:ring-action-500 focus:border-action-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Nom&nbsp;*
                  </label>
                  <div className="mt-1">
                    <input
                      required
                      type="text"
                      name="last-name"
                      id="last-name"
                      autoComplete="family-name"
                      className="shadow-sm focus:ring-action-500 focus:border-action-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email&nbsp;*
                  </label>
                  <div className="mt-1">
                    <input
                      required
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      className="shadow-sm focus:ring-action-500 focus:border-action-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Numéro de téléphone
                  </label>
                  <div className="mt-1">
                    <input
                      id="phone"
                      name="phone"
                      type="tel"
                      autoComplete="tel"
                      className="shadow-sm focus:ring-action-500 focus:border-action-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="organisation"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Organisation
                  </label>
                  <div className="mt-1">
                    <input
                      id="organisation"
                      name="organisation"
                      type="text"
                      className="shadow-sm focus:ring-action-500 focus:border-action-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="role"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Fonction
                  </label>
                  <div className="mt-1">
                    <input
                      id="role"
                      name="role"
                      type="text"
                      className="shadow-sm focus:ring-action-500 focus:border-action-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <label
                    htmlFor="description"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Projet
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="description"
                      name="description"
                      rows={3}
                      className="shadow-sm focus:ring-action-500 focus:border-action-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                      defaultValue={""}
                    />
                  </div>
                </div>

                <div className="sm:col-span-6 hidden">
                  <label
                    htmlFor="laisser-vide"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Laisser vide&nbsp;*
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="laisser-vide"
                      name="laisser-vide"
                      rows={3}
                      className="shadow-sm focus:ring-action-500 focus:border-action-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                      value={honeypot}
                      onChange={e => setHoneypot(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-5">
            <div className="flex justify-end">
              <button
                type="submit"
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-action-600 hover:bg-action-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-action-500"
              >
                Obtenir plus d’informations
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
