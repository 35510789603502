import * as React from "react";
import { Seo } from "../../components/Seo";
import { Header, Footer } from "../../components/layout/index";
import { ContactForm } from "../../components/atoms/ContactForm";

const ContactPage = props => {
  return (
    <>
      <Seo title="Demander une démo - Solution de téléexpertise et de e-RCP" />
      <Header />
      <ContactForm />
      <Footer />
    </>
  );
};

export default ContactPage;
